import { Component } from "@/scripts/extends";

export default (ctx) =>
	class Cookiebanner extends Component(ctx) {
		events() {
			this.acceptAll.addEventListener("click", () => this.acceptAllCookies());
			this.getPreferences.addEventListener("click", () => this.getPreferencesBanner());
			this.acceptAllPreferences.addEventListener("click", () => this.acceptAllCookies());
			this.goBack.addEventListener("click", () => this.goBackToAllBanner());
			this.acceptSelection.addEventListener("click", () => this.getAllCheckBoxes());
		}

		mounted({ openCb = () => null } = {}) {
			this.preferencesBanner = this.el.querySelector(".preferences-cookies");
			this.acceptAllBanner = this.el.querySelector(".all-cookies");
			this.preferencesBanner.style.display = "none";
			this.openCb = openCb;
			this.daysToExpire = new Date(new Date().setMonth(new Date().getMonth() + 12));
			this.acceptAll = this.el.querySelector(".c-cookiebanner__accept-all");
			this.acceptSelection = this.el.querySelector(".c-cookiebanner__accept-selection");
			this.acceptAllPreferences = this.el.querySelector(".c-cookiebanner__accept-all-preferences");
			this.getPreferences = this.el.querySelector(".c-cookiebanner__get-preferences");
			this.goBack = this.el.querySelector(".c-cookiebanner__back");
			if (!this.hasCookie()) {
				this.el.classList.add("is-open");
			}
		}

		hasCookie() {
			const cookie = this.getCookie();
			if (cookie !== "") {
				return true;
			}
			return false;
		}

		getAllCheckBoxes() {
			const checkboxes = this.el.querySelectorAll(".c-toggle__input:checked");
			const checkboxIDs = [];
			checkboxes.forEach((element) => {
				checkboxIDs.push(parseInt(element.id));
			});
			const maxCookieLevel = Math.max(...checkboxIDs);
			document.cookie = `frdncookieconsent=${maxCookieLevel};path=/;SameSite=Lax;expires=${this.daysToExpire}`;
			this.el.classList.remove("is-open");
		}

		getCookie() {
			const name = "frdncookieconsent";
			const decodedCookie = decodeURIComponent(document.cookie);
			const ca = decodedCookie.split(";");
			for (let i = 0; i < ca.length; i += 1) {
				let c = ca[i];
				while (c.charAt(0) === " ") {
					c = c.substring(1);
				}
				if (c.indexOf(name) === 0) {
					return c.substring(name.length, c.length);
				}
			}
			return "";
		}

		acceptAllCookies() {
			document.cookie = `frdncookieconsent=2;path=/;SameSite=Lax;expires=${this.daysToExpire}`;
			this.el.classList.remove("is-open");
		}

		goBackToAllBanner() {
			this.acceptAllBanner.style.display = "block";
			this.preferencesBanner.style.display = "none";
		}

		getPreferencesBanner() {
			this.acceptAllBanner.style.display = "none";
			this.preferencesBanner.style.display = "block";
		}
	};
