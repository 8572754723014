import { Component } from "@/scripts/extends";
import { getBoundingBox } from "@/scripts/utils/dom";
import debounce from "lodash.debounce";
import ResizeObserver from "resize-observer-polyfill";

export default (ctx) =>
	class Collapse extends Component(ctx) {
		events() {
			this.trigger.addEventListener("click", () => this.toggle());
			this.resizeObserver = new ResizeObserver(debounce(() => this.handleResize(), 100));
			this.resizeObserver.observe(document.body);
		}

		mounted({ index, openCb = () => null } = {}) {
			this.openCb = openCb;
			this.index = index;

			this.toggleClass = "is-open";
			this.trigger = this.el.querySelector(".c-collapse__trigger");
			this.content = this.el.querySelector(".c-collapse__content");
			this.contentContainer = this.el.querySelector(".c-collapse__content-container");

			this.isToggled = this.isToggled();
		}

		isToggled() {
			return this.el.classList.contains(".is-open");
		}

		toggle() {
			this.isToggled = !this.isToggled;

			if (this.isToggled) {
				this.open();
			} else {
				this.close();
			}

			if (this.openCb) {
				this.openCb({
					index: this.index,
				});
			}
		}

		getHeight() {
			return getBoundingBox(this.contentContainer, ["height"])[0];
		}

		open() {
			this.update();

			this.el.classList.add(this.toggleClass);

			this.isToggled = true;
		}

		close() {
			this.content.style.height = 0;

			this.el.classList.remove(this.toggleClass);

			this.isToggled = false;
		}

		update() {
			this.height = this.getHeight();
			this.content.style.height = `${this.height}px`;
		}

		handleResize() {
			if (this.isToggled) {
				this.update();
			}
		}
	};
