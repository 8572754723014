import Hamburger from "@/components/Hamburger/Hamburger.scripts";
import { Component } from "@/scripts/extends";
import Headroom from "headroom.js";

export default (ctx) =>
	class SiteHeader extends Component(ctx) {
		mounted() {
			this.darkClass = "s-dark";

			this.isDark = this.initialIsDark();

			this.headRoom = new Headroom(this.el, {
				offset: 50,
				classes: {
					pinned: "c-site-header--pinned",
					unpinned: "c-site-header--unpinned",
				},
				onTop: () => this.onTop(),
				onNotTop: () => this.onNotTop(),
			});

			this.headRoom.init();

			this.hamburger = new (Hamburger(ctx))(this.el.querySelector(".c-site-header__hamburger"), {
				openCb: () => this.onEnableHamburger(),
			});
		}

		initialIsDark() {
			return this.el.classList.contains(this.darkClass);
		}

		onEnableHamburger() {
			ctx.EventEmitter.emit(ctx.CONSTANTS.EVENTS.SITE_HEADER_HAMBURGER_OPEN);
		}

		onTop() {
			if (this.isDark && !this.initialIsDark()) {
				this.el.classList.add(this.darkClass);
			}
		}

		onNotTop() {
			if (this.isDark && this.initialIsDark()) {
				this.el.classList.remove(this.darkClass);
			}
		}
	};
