import { Component } from "@/scripts/extends";

export default (ctx) =>
	class SiteMobileOverlay extends Component(ctx) {
		events() {
			this.close.addEventListener("click", (e) => this.onClick(e));

			ctx.EventEmitter.on(ctx.CONSTANTS.EVENTS.SITE_HEADER_HAMBURGER_OPEN, () =>
				this.onSiteHeaderHamburgerOpen()
			);
			ctx.EventEmitter.on(ctx.CONSTANTS.EVENTS.SITE_HEADER_HAMBURGER_CLOSE, () =>
				this.onSiteHeaderHamburgerClose()
			);
		}

		mounted() {
			this.isShownClass = "is-shown";
			this.isShown = this.initIsShown();

			this.close = this.el.querySelector(".c-site-mobile-overlay__close");
		}

		onClick(e) {
			e.preventDefault();

			ctx.EventEmitter.emit(ctx.CONSTANTS.EVENTS.SITE_HEADER_HAMBURGER_CLOSE);
		}

		initIsShown() {
			return this.el.classList.contains(this.isShownClass);
		}

		onSiteHeaderHamburgerOpen() {
			this.show();
		}

		onSiteHeaderHamburgerClose() {
			this.hide();
		}

		show() {
			this.isShown = true;
			this.el.classList.add(this.isShownClass);

			ctx.EventEmitter.emit(ctx.CONSTANTS.EVENTS.SITE_MOBILE_OVERLAY_SHOW);
		}

		hide() {
			this.isShown = false;
			this.el.classList.remove(this.isShownClass);

			ctx.EventEmitter.emit(ctx.CONSTANTS.EVENTS.SITE_MOBILE_OVERLAY_HIDE);
		}
	};
