import { Component } from "@/scripts/extends";
import { getBoundingBox } from "@/scripts/utils/dom";
import debounce from "lodash.debounce";
import ResizeObserver from "resize-observer-polyfill";

export default (ctx) =>
	class Particles extends Component(ctx) {
		events() {
			this.resizeObserver = new ResizeObserver(debounce(() => this.handleResize(), 100));
			this.resizeObserver.observe(document.body);
		}

		mounted() {
			this.fps = 30;
			this.points = [];
			this.maxDist = 300;
			this.color = "255,255,255";
			this.speed = 0.5;
			this.width = this.getWidth();
			this.useFullWidth = this.el.hasAttribute("data-useFullWidth")
				? this.el.getAttribute("data-useFullWidth")
				: false;
			this.displayWidth = this.useFullWidth ? this.width : this.width * 0.5;
			this.height = this.getHeight();

			this.initPoints = this.initPoints.bind(this);
			this.init();
		}

		getWidth() {
			return getBoundingBox(this.el, ["width"])[0];
		}

		getHeight() {
			return getBoundingBox(this.el, ["height"])[0];
		}

		init() {
			this.canvas = this.el;
			this.ctx = this.canvas.getContext("2d", { alpha: false });

			this.handleResize();

			if (window.innerWidth >= ctx.CONSTANTS.BREAKPOINTS["9"]) {
				this.generatePoints(60);
				this.initPoints();
			}
		}

		initPoints() {
			setTimeout(() => {
				requestAnimationFrame(this.initPoints);
			}, 1000 / this.fps);

			this.ctx.clearRect(0, 0, this.width, this.height);

			this.points.forEach((point) => {
				this.collision(point, this.maxDist);
				this.draw(point);
				this.update(point);
			});
		}

		point() {
			this.x = Math.floor(Math.random() * (this.displayWidth + this.maxDist) - this.maxDist * 0.5);
			this.y = Math.floor(Math.random() * (this.height + this.maxDist) - this.maxDist * 0.5);
			this.vx = Math.floor(Math.random() * 1 - 0.5);
			this.vy = Math.floor(Math.random() * 1 - 0.5);
			this.diameter = Math.floor(Math.random() * 6 + 4);

			this.points.push({
				x: this.x,
				y: this.y,
				vx: this.vx,
				vy: this.vy,
				diameter: this.diameter,
			});
		}

		generatePoints(amount) {
			for (let i = 0; i < amount; i += 1) {
				this.point();
			}
		}

		draw({ x, y, diameter }) {
			this.ctx.beginPath();
			this.ctx.fillStyle = `rgba(${this.color}, 0.4)`;
			this.ctx.arc(x, y, diameter, 0, 2 * Math.PI);
			this.ctx.closePath();
			this.ctx.fill();
			this.ctx.strokeStyle = `rgba(${this.color}, 0.6)`;
			this.ctx.stroke();
		}

		update(point) {
			const ms = this.speed / 2;

			point.x += point.vx * ms;
			point.y += point.vy * ms;

			if (point.x > this.displayWidth + this.maxDist / 2) {
				point.x = -(this.maxDist / 2);
			} else if (point.x < -(this.maxDist / 2)) {
				point.x = this.displayWidth + this.maxDist / 2;
			}

			if (point.y > this.height + this.maxDist / 2) {
				point.y = -(this.maxDist / 2);
			} else if (point.y < -(this.maxDist / 2)) {
				point.y = this.height + this.maxDist / 2;
			}
		}

		collision(pointObj, maxDist) {
			this.points.forEach((point) => {
				// Do not intersect with itself
				if (pointObj !== point) {
					const dist = Math.floor(
						Math.sqrt(Math.pow(pointObj.x - point.x, 2) + Math.pow(pointObj.y - point.y, 2))
					);

					if (dist < maxDist) {
						this.ctx.beginPath();
						this.ctx.moveTo(pointObj.x, pointObj.y);
						this.ctx.strokeStyle = `rgba(${this.color},${0.6 * Math.pow((maxDist - dist) / maxDist, 5)})`;
						this.ctx.moveTo(pointObj.x, pointObj.y);
						this.ctx.lineTo(point.x, point.y);
						this.ctx.closePath();
						this.ctx.lineWidth = 2;
						this.ctx.stroke();
					}
				}
			});
		}

		handleResize() {
			this.width = this.getWidth();
			this.displayWidth = this.useFullWidth ? this.width : this.width * 0.5;
			this.height = this.getHeight();

			this.scaleCanvas(this.width, this.height);
		}

		scaleCanvas(width, height) {
			const devicePixelRatio = 1;
			const backingStoreRatio =
				this.ctx.webkitBackingStorePixelRatio ||
				this.ctx.mozBackingStorePixelRatio ||
				this.ctx.msBackingStorePixelRatio ||
				this.ctx.oBackingStorePixelRatio ||
				this.ctx.backingStorePixelRatio ||
				1;
			const ratio = devicePixelRatio / backingStoreRatio;

			if (devicePixelRatio !== backingStoreRatio) {
				this.canvas.width = width * ratio;
				this.canvas.height = height * ratio;

				this.canvas.style.width = `${width}px`;
				this.canvas.style.height = `${height}px`;
			} else {
				this.canvas.width = width;
				this.canvas.height = height;
				this.canvas.style.width = "";
				this.canvas.style.height = "";
			}

			this.ctx.scale(ratio, ratio);
		}
	};
