import { Component } from "@/scripts/extends";
import { getBoundingBox } from "@/scripts/utils/dom";
import debounce from "lodash.debounce";
import ResizeObserver from "resize-observer-polyfill";

export default (ctx) =>
	class Flyout extends Component(ctx) {
		events() {
			this.resizeObserver = new ResizeObserver(debounce(() => this.handleResize(), 100));
			this.resizeObserver.observe(document.body);
		}

		mounted() {
			this.content = this.el.querySelector(".c-flyout__content");

			this.handleResize();
		}

		handleResize() {
			this.handleOffset();
		}

		resetOffset() {
			this.content.removeAttribute("style");
		}

		handleOffset() {
			this.resetOffset();

			const boundingBox = getBoundingBox(this.content, ["x", "width"]);
			const x = boundingBox[0];
			const width = boundingBox[1];

			if (x < 0) {
				this.setOffsetFromLeft(x);
			} else if (x > 0) {
				this.setOffsetFromRight(x, width);
			}
		}

		setOffsetFromLeft(x) {
			const absoluteX = Math.abs(x);
			const viewportSpacing = 15;
			const offset = absoluteX + viewportSpacing;

			this.content.style.marginLeft = `${offset}px`;
			this.content.style.maxWidth = `calc(100vw - ${this.viewportSpacing * 2}px)`;
		}

		setOffsetFromRight(x, width) {
			const vw = getBoundingBox(document.body, ["width"])[0];
			const viewportSpacing = 15;
			const distanceFromLeft = x + width + viewportSpacing;
			const offset = vw - distanceFromLeft;

			if (offset >= 0) {
				return;
			}

			this.content.style.marginLeft = `${offset}px`;
			this.content.style.maxWidth = `calc(100vw - ${this.viewportSpacing * 2}px)`;
		}
	};
