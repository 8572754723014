import { Component } from "@/scripts/extends";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";

export default (ctx) =>
	class Carousel extends Component(ctx) {
		mounted() {
			Swiper.use([Navigation, Pagination, Autoplay]);

			this.initSwiper();
		}

		initSwiper() {
			this.swiper = new Swiper(this.el.querySelector(".c-carousel__container"), {
				loop: true,
				simulateTouch: false,
				autoplay: {
					delay: 15000,
				},
				pagination: {
					el: ".c-carousel__pagination",
					renderBullet(_, className) {
						return `<span class='c-carousel__dot ${className}'></span>`;
					},
				},
				navigation: {
					nextEl: ".c-carousel__next",
					prevEl: ".c-carousel__prev",
				},
			});
		}
	};
