import NavCollapse from "@/components/NavCollapse/NavCollapse.scripts";
import { Component } from "@/scripts/extends";

export default (ctx) =>
	class SiteMobileNav extends Component(ctx) {
		mounted() {
			this.items = this.el.querySelectorAll(".c-site-mobile-nav__collapse");

			this.collapses = this.initCollapses();
		}

		initCollapses() {
			return [...this.items].map(
				(item, index) =>
					new (NavCollapse(ctx))(item, {
						index,
						openCb: (params) => this.disableCollapses(params),
					})
			);
		}

		disableCollapses({ index }) {
			this.collapses.forEach((collapse) => {
				if (collapse.index !== index && collapse.isToggled) {
					collapse.close();
				}
			});
		}
	};
