import { Component } from "@/scripts/extends";

export default (ctx) =>
	class Hamburger extends Component(ctx) {
		events() {
			this.el.addEventListener("click", (e) => this.onClick(e));

			ctx.EventEmitter.on(ctx.CONSTANTS.EVENTS.SITE_HEADER_HAMBURGER_CLOSE, () => this.close());
		}

		mounted({ openCb = () => null } = {}) {
			this.openCb = openCb;
		}

		onClick(e) {
			e.preventDefault();

			this.open();
		}

		open() {
			this.el.setAttribute("aria-expanded", "true");

			this.openCb();
		}

		close() {
			this.el.setAttribute("aria-expanded", "false");
		}
	};
