import { Component } from "@/scripts/extends";

export default (ctx) =>
	class Tabs extends Component(ctx) {
		mounted() {
			this.tabs = this.el.querySelectorAll(".c-tabs__button");

			this.initTabs();
		}

		initTabs() {
			return [...this.tabs].map((tab) => tab.addEventListener("click", (e) => this.click(e)));
		}

		click({ currentTarget }) {
			currentTarget.scrollIntoView({
				block: "nearest",
				inline: "center",
				behavior: "smooth",
			});
		}
	};
