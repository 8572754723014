import { Component } from "@/scripts/extends";

export default (ctx) =>
	class InputSubmit extends Component(ctx) {
		events() {
			if (this.target) {
				this.target.addEventListener("focus", () => this.onFocus());
				this.target.addEventListener("blur", () => this.onBlur());
			}
		}

		mounted() {
			this.isFocusedClass = "is-focused";
			this.isFocused = this.initIsFocused();
			this.target = this.el.querySelector(".c-input-submit__field");
		}

		initIsFocused() {
			return this.el.classList.contains(this.isFocusedClass);
		}

		onFocus() {
			this.focus();
		}

		onBlur() {
			this.blur();
		}

		focus() {
			this.isFocused = true;
			this.el.classList.add(this.isFocusedClass);
		}

		blur() {
			this.isFocused = false;
			this.el.classList.remove(this.isFocusedClass);
		}
	};
