import Accordeon from "./Accordeon/Accordeon.scripts";
import Carousel from "./Carousel/Carousel.scripts";
import Collapse from "./Collapse/Collapse.scripts";
import Cookiebanner from "./Cookiebanner/Cookiebanner.scripts";
import Flyout from "./Flyout/Flyout.scripts";
import Hamburger from "./Hamburger/Hamburger.scripts";
import InputSubmit from "./InputSubmit/InputSubmit.scripts";
import NavCollapse from "./NavCollapse/NavCollapse.scripts";
import Particles from "./Particles/Particles.scripts";
import SiteHeader from "./SiteHeader/SiteHeader.scripts";
import SiteMobileNav from "./SiteMobileNav/SiteMobileNav.scripts";
import SiteMobileOverlay from "./SiteMobileOverlay/SiteMobileOverlay.scripts";
import Tabs from "./Tabs/Tabs.scripts";

export default {
	SiteHeader,
	Hamburger,
	Flyout,
	Collapse,
	InputSubmit,
	NavCollapse,
	SiteMobileOverlay,
	SiteMobileNav,
	Accordeon,
	Carousel,
	Tabs,
	Particles,
	Cookiebanner,
};
