import { Component } from "@/scripts/extends";

export default (ctx) =>
	class Body extends Component(ctx) {
		events() {
			ctx.EventEmitter.on(ctx.CONSTANTS.EVENTS.SITE_MOBILE_OVERLAY_SHOW, () => this.toggleSiteMobileOverlay());
			ctx.EventEmitter.on(ctx.CONSTANTS.EVENTS.SITE_MOBILE_OVERLAY_HIDE, () => this.toggleSiteMobileOverlay());
		}

		mounted() {
			this.isScrollDisabledClass = "has-scroll-disabled";
		}

		toggleSiteMobileOverlay() {
			this.el.classList.toggle(this.isScrollDisabledClass);
		}
	};
