/**
 * Import and load the main css file (this is exported in their separate css
 * file main.css when buildling)
 */
import "@/styles/main.scss";

import components from "@/components";
import { init as initComponent } from "@/scripts/utils/dom";

import globalComponents from "./components";

const init = () => {
	initComponent(document.body, globalComponents.Body);

	initComponent(".js-carousel", components.Carousel);
	initComponent(".js-collapse", components.Collapse);
	initComponent(".js-nav-collapse", components.NavCollapse);
	initComponent(".js-hamburger", components.Hamburger);
	initComponent(".js-site-header", components.SiteHeader);
	initComponent(".js-site-mobile-overlay", components.SiteMobileOverlay);
	initComponent(".js-site-mobile-nav", components.SiteMobileNav);
	initComponent(".js-flyout", components.Flyout);
	initComponent(".js-accordeon", components.Accordeon);
	initComponent(".js-tabs", components.Tabs);
	initComponent(".js-particles", components.Particles);
	initComponent(".js-input-submit", components.InputSubmit);
	initComponent(".js-cookiebanner", components.Cookiebanner);
};

/**
 * Check if the document is ready to initialise the JavaScript components.
 * Interactive indicates: execute when document has finished loading, but
 * images, stylesheets and frames are still loading.
 *
 * https://developer.mozilla.org/en-US/docs/Web/API/Document/readyState
 */
document.onreadystatechange = () => {
	if (document.readyState === "interactive") {
		init();
	}
};
