import CONSTANTS from "@/scripts/constants";
import { EventEmitter, Store } from "@/scripts/singletons";

export const init = (selector, Module = null, params = {}) => {
	const els = typeof selector === "string" ? document.querySelectorAll(selector) || [] : [selector];
	const ctx = {
		EventEmitter,
		Store,
		CONSTANTS,
	};

	const instances = Module ? [...els].map((el) => new (Module(ctx))(el, params)) : [...els];

	if (instances.length === 1) {
		return instances[0] || null;
	}

	return instances || [];
};

export const getBounds = (el) => el.getBoundingClientRect() || {};

export const getBoundingBox = (el, keys) => {
	const bounds = getBounds(el);

	return keys ? keys.map((key) => bounds[key]) : bounds;
};

export const getKeyboardFocusableElements = (parent) =>
	[...parent.querySelectorAll('a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])')].filter(
		(el) => !el.hasAttribute("disabled")
	);
