export default () =>
	class Component {
		constructor(el, ...params) {
			this.el = el;

			if (this.mounted) {
				this.mounted(...params);
			}

			if (this.events) {
				this.events();
			}
		}
	};
