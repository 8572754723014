import Collapse from "@/components/Collapse/Collapse.scripts";
import { Component } from "@/scripts/extends";

export default (ctx) =>
	class Accordeon extends Component(ctx) {
		mounted() {
			this.items = this.el.querySelectorAll(".c-accordeon__item");

			this.collapses = this.initCollapses();
		}

		initCollapses() {
			return [...this.items].map(
				(item, index) =>
					new (Collapse(ctx))(item, {
						index,
						openCb: (params) => this.disableCollapses(params),
					})
			);
		}

		disableCollapses({ index }) {
			this.collapses.forEach((collapse) => {
				if (collapse.index !== index && collapse.isToggled) {
					collapse.close();
				}
			});
		}
	};
